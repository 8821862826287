
import './App.css';
import Home from './views/home/home';
import Auth from './views/auth/auth';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
function App() {
  return (
    <Router basename="/">
    <div className="App">
    <Switch>
      <Route exact path="/" component={Auth} />
      <Route path="/Home" component={Home} />
    </Switch>
    </div>
    </Router>
  );
}

export default App;
