import React from 'react'
import { Container, Grid, Paper, TextField, IconButton, Tooltip, Button, LinearProgress, TableContainer, Table, TableHead, TableCell, TableRow,TableBody,Dialog,DialogContent } from '@material-ui/core'
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import SaveIcon from '@material-ui/icons/Save';
import SnakBar from '../../consts/message';
import { Api, ImageApi } from '../../api/apiv2';
export default function ProcceddingBook(){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [attachment, setAttachment] = React.useState(null)
    const [massg,setMassg] = React.useState({})
    const [p_book, setP_Book] = React.useState([])
    const [view, setView] = React.useState(false)
    const [viewItem, setViewItem] = React.useState({})
    const onChange = (e)=>{

        if(e.target.name === 'attachment'){
            setAttachment(e.target.files[0])
        }
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const onSubmit = ()=>{
        if(!formData.head){
            setMassg({
                open : true,
                severity : 'error',
                massg: 'Please fill all required filled'
            })
        }else {
            setLoading(true)
            const data = new FormData
            data.append('head', formData.head)
            data.append('file',attachment)
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            fetch(Api+'update_p_book',{
                method : 'POST',
                body : data
            })
            .then(res=>res.json())
            .then(res=>{
             setLoading(false)
             console.log(res)
             if(res.code === 200){
                setMassg({
                open : true,
                severity : 'success',
                massg : res.massg
                })
            }else{
                setMassg({
                open : true,
                severity : 'error',
                massg : res.massg
                })
            }
            })
            .catch(err=>{
                setLoading(false)
                setMassg({
                    open : true,
                    severity : 'error',
                    massg : 'Faild to connect to the server'
                })
            })
        }
    }

    React.useEffect(()=>{
        fetch(Api+'b_books')
        .then(res=>res.json())
        .then(res=>setP_Book(res))
        .catch(err=>{
           console.log(err)
        })
    },[])
    const onView = (item)=>{
        setView(true)
        setViewItem(item)
    }

    return(
        <Container maxWidth="xl">
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    {loading ? <LinearProgress /> : ''}
                    <Paper style={{padding: 5}}>
                        <h2>Add New</h2>
                        <TextField
                            variant='outlined'
                            label="Heading"
                            fullWidth
                            name="head"
                            value={formData.head}
                            onChange={onChange}
                            error={err.head}
                            style={{marginTop : 5}}
                        />
                        <TextField
                            variant='outlined'
                            helperText="Attachment"
                            fullWidth
                            name="attachment"
                            onChange={onChange}
                            error={err.attachment}
                            style={{marginTop : 5}}
                            type="file"
                        />
                        <Editor
                            editorState={editorState}
                            wrapperClassName="wrapper-class"
                            editorClassName="editor-class"
                            toolbarClassName="toolbar-class"
                            onEditorStateChange={onEditorStateChange}
                            editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}}
                            toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
                        
                            />
                            <textarea
                            disabled
                            style={{display: 'none'}}
                            value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                            // onChange={({target : {value}})=>setData(value)}
                        />

                        <Button
                            variant="contained"
                            color="primary"
                            fullWidth
                            onClick={onSubmit}
                        >
                            Save
                        </Button>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Paper>
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Heading</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Attachment</TableCell>
                                <TableCell>description</TableCell>
                            </TableRow>
                        </TableHead>
                        
                        <TableBody>
                            
                            {
                                p_book.map((item,index)=>
                                    <TableRow key={index}>
                                        <TableCell>{item.head}</TableCell>
                                        <TableCell>{item.date}</TableCell>
                                        <TableCell>
                                            {
                                                item.attachment === "null" ? 'Not available' : (
                                                    <a href={ImageApi+'p_book/'+item.attachment} target={'_blank'} download>
                                                        <Button>
                                                            Download
                                                        </Button>
                                                    </a>


                                                )
                                            }
                                        </TableCell>
                                        <TableCell>
                                        <Button onClick={()=>onView(item.content)}>View Description</Button>
                                        </TableCell>
                                        
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                    <Dialog open={view} onClose={()=>setView(false)}>
                        <DialogContent>
                        <div dangerouslySetInnerHTML={{__html : viewItem}}>

                        </div>
                        </DialogContent>
                    </Dialog>
                    <SnakBar massg={massg} setMassg={setMassg} />
                </TableContainer>
                    </Paper>
                </Grid>
            </Grid>
        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}