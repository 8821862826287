import React from 'react'
import {
    Container, Table, TableBody, TableCell, TableContainer, TableHead, TableRow
} from '@material-ui/core'
import {Api} from '../api/apiv2'

export default function Messages(){
    const [massg, setMassge] = React.useState([])
    React.useState(()=>{
        fetch(Api+'messages')
        .then(res=>res.json())
        .then(res=>setMassge(res))
    },[])

    return(
        <Container>
        <TableContainer>
            <Table>
                <TableHead>
                    <TableCell>Name</TableCell>
                    <TableCell>Contact No</TableCell>
                    <TableCell>Email</TableCell>
                    <TableCell>Message</TableCell>
                </TableHead>
                <TableBody>
                {
                    massg.map((item,index)=>
                        <TableRow key={index}>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.c_no}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.massg}</TableCell>
                        </TableRow>
                    
                    )
                }
                </TableBody>
            </Table>
        </TableContainer>
        </Container>
    )
}