import React from 'react'
import {
    Button,
    CircularProgress,
    Container, Grid, TextField,
    Tooltip,
    IconButton
} from '@material-ui/core'
import { Api } from '../../api/apiv2'
import SnakBar from '../../consts/message'
import FileResizer from 'react-image-file-resizer'
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import SaveIcon from '@material-ui/icons/Save';


export default function AddCourse(){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }

    const [form, setForm] = React.useState({})
    const [onerror, setOnErr] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [notifi, setNotifi] = React.useState({})
    const [image,setImage] = React.useState('')
    const handleChange = (e)=>{
        const name = e.target.name
        const val = e.target.value
        setForm({
            ...form,
            [name] : val
        })
    }

    const handleImage = (e)=>{
        if(e.target.name === 'image'){
            try {
                FileResizer.imageFileResizer(
                e.target.files[0],
                800,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                   setImage(uri) 
                },
                "file",
                200,
                200
                );
          
            
            } catch (err) {
                console.log(err);
            }
    }
}
    const validation = ()=>{
        let valid = true
        let error = {}

        if(!form.name){
            valid = false
            error['name'] = true
        }else if(!form.duration){
            valid = false
            error['duration'] = true
        }
        else if(!form.inst_pay){
            valid = false
            error['inst_pay'] = true
        }
        else if(!form.reg_fee){
            valid = false
            error['reg_fee'] = true
        }
        else if(!form.addm_fee){
            valid = false
            error['addm_fee'] = true
        }
        else if(!form.sem){
            valid = false
            error['sem'] = true
        }
        else if(!form.i_fee){
            valid = false
            error['i_fee'] = true
        }
        else if(!form.center_fee){
            valid = false
            error['center_fee'] = true
        }
        else if(!form.laboratory_fee){
            valid = false
            error['laboratory_fee'] = true
        }
        else if(!form.form_fillup_fee){
            valid = false
            error['form_fillup_fee'] = true
        }
        if(!form.course_type){
            valid = false
            error['course_type'] = true
        }
        // else if(!form.description){
        //     valid = false
        //     error['description'] = true
        // }
        if(!image){
            valid = false
            error['image'] = true
        }
        if(editorState === EditorState.createEmpty()){
            valid = false
            error['editorState'] = true
        }

        setOnErr(error)

        return valid
       
    }

    const onSubmit = ()=>{

        if(validation()){
            setLoading(true)
            const formData = new FormData

            Object.keys(form).map(item=>{
                formData.append(item, form[item])
            })
            formData.append('image',image)
            formData.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            fetch(Api+'addCourse',{
                method : 'POST',
                body : formData
            }).then(res=>res.json())
            .then(res=>{
                setLoading(false)
                setNotifi({
                    massg : res.massg,
                    open : true,
                    severity : res.code === 201 ? 'success' : 'error'
                })
            })
            .catch(err=>console.log(err))
        }else{
            setNotifi({
                massg : "All fill required",
                open : true,
                severity : 'error'
            })
        }
    }

    return (
        <Container>
        <Grid container spacing={1}>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="name"
                    onChange={handleChange}
                    value={form.name}
                    error={onerror.name}
                    label="name"
                    fullWidth
                    type="text"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="description"
                    onChange={handleChange}
                    value={form.description}
                    error={onerror.description}
                    label="Description"
                    fullWidth
                    type="text"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="duration"
                    onChange={handleChange}
                    value={form.duration}
                    error={onerror.duration}
                    label="Duration"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="inst_pay"
                    onChange={handleChange}
                    value={form.inst_pay}
                    error={onerror.inst_pay}
                    label="Installment payment"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="reg_fee"
                    onChange={handleChange}
                    value={form.reg_fee}
                    error={onerror.reg_fee}
                    label="Registration Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="addm_fee"
                    onChange={handleChange}
                    value={form.addm_fee}
                    error={onerror.addm_fee}
                    label="Addmission Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="sem"
                    onChange={handleChange}
                    value={form.sem}
                    error={onerror.sem}
                    label="Semmester"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="i_fee"
                    onChange={handleChange}
                    value={form.i_fee}
                    error={onerror.i_fee}
                    label="I Card Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="center_fee"
                    onChange={handleChange}
                    value={form.center_fee}
                    error={onerror.center_fee}
                    label="Center Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="laboratory_fee"
                    onChange={handleChange}
                    value={form.laboratory_fee}
                    error={onerror.laboratory_fee}
                    label="Laboratory Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="form_fillup_fee"
                    onChange={handleChange}
                    value={form.form_fillup_fee}
                    error={onerror.form_fillup_fee}
                    label="Form Fill Up Fee"
                    fullWidth
                    type="number"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="image"
                    onChange={handleImage}
                    error={onerror.image}
                    helperText="course image"
                    fullWidth
                    type="file"
                />
            </Grid>
            <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="course_type"
                    onChange={handleChange}
                    value={form.course_type}
                    error={onerror.course_type}
                    label="Course Type"
                    fullWidth
                   
                />
            </Grid>
            {/* <Grid sm={4} item >
                <TextField
                    variant="outlined"
                    name="faculty"
                    onChange={handleChange}
                    value={form.faculty}
                    error={onerror.faculty}
                    label="Faculty"
                    fullWidth
                    type="number"
                />
            </Grid> */}
            <Grid sm={12} item>
            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid ',minHeight : 200, borderColor : onerror.editorState ? 'red' : '#e5e5e5'}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />
            </Grid>
            <Grid sm={4} item >
                {
                    loading ? <CircularProgress /> : ''
                }
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={onSubmit}
                    disabled={loading}
                >
                    Save
                </Button>
            </Grid>
        </Grid>
        
        <SnakBar massg={notifi} setMassg={setNotifi} />
        </Container>
    )
}