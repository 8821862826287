import React from 'react'
import {
    Button,
    Container, Table, TableBody, TableCell, TableHead, TableRow,LinearProgress, TableContainer
} from '@material-ui/core'
import { Api } from '../../api/apiv2'
import SnakBar from '../../consts/message'


export default function AllCourses(){

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [massg, setMassg] = React.useState({})
    const [refresh, setRefresh] = React.useState(Math.random())
    React.useEffect(()=>{
        fetch(Api+'allCourse')
        .then(res=>res.json())
        .then(res=>setData(res))
    },[refresh])

    const onDelete = (id)=>{
        setLoading(true)
        fetch(Api+'delete_course',{
            method : 'POST',
            body : JSON.stringify({
                id : id
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setMassg({
                massg : res.massg,
                open : true,
                severity : res.code === 201 ? 'success' : 'error'
            })
            setRefresh(Math.random())
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                massg : "Faild to connect to server!, Please try again",
                open : true,
                severity :  'error'
            })
        })
    }

    return (
        <TableContainer>
            {
                        loading ? <LinearProgress /> : ''
                    }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Course Name</TableCell>
                        <TableCell>Duration</TableCell>
                        <TableCell>Installment Payment</TableCell>
                        <TableCell>Registration Fee</TableCell>
                        <TableCell>Addmission Fee</TableCell>
                        <TableCell>Semmester</TableCell>
                        <TableCell>I card Fee</TableCell>
                        <TableCell>Center Fee</TableCell>
                        <TableCell>Lab Fee</TableCell>
                        <TableCell>Form Fillup Fee</TableCell>
                        <TableCell>Delete</TableCell>
                    </TableRow>
                </TableHead>
                
                <TableBody>
                    
                    {
                        data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.duration}</TableCell>
                                <TableCell>{item.inst_pay}</TableCell>
                                <TableCell>{item.reg_fee}</TableCell>
                                <TableCell>{item.addm_fee}</TableCell>
                                <TableCell>{item.sem}</TableCell>
                                <TableCell>{item.i_card_fee}</TableCell>
                                <TableCell>{item.center_fee}</TableCell>
                                <TableCell>{item.lab_fee}</TableCell>
                                <TableCell>{item.form_fillup}</TableCell>
                                <Button onClick={()=>onDelete(item.id)}>Delete</Button>
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
            <SnakBar massg={massg} setMassg={setMassg} />
        </TableContainer>
    )
}