import React from 'react'
import {
    Button,
    Container, Table, TableBody, TableCell, TableHead, TableRow,LinearProgress, TableContainer, Dialog, DialogContent
} from '@material-ui/core'
import { Api , ImageApi} from '../../api/apiv2'
import SnakBar from '../../consts/message'


export default function AchademicRegister(){

    const [data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [massg, setMassg] = React.useState({})
    const [refresh, setRefresh] = React.useState(Math.random())
    const [view, setView] = React.useState(false)
    const [viewItem, setViewItem] = React.useState({})
    React.useEffect(()=>{
        fetch(Api+'ac_register')
        .then(res=>res.json())
        .then(res=>setData(res))
        .catch((err)=>console.log(err))
    },[refresh])

    const onView = (item)=>{
        setView(true)
        setViewItem(item)
    }

    return (
        <TableContainer>
            {
                        loading ? <LinearProgress /> : ''
                    }
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Faculty Name</TableCell>
                        <TableCell>Subject Name</TableCell>
                        <TableCell>Update Date</TableCell>
                        <TableCell>Update Time</TableCell>
                        <TableCell>Attachment</TableCell>
                        <TableCell>description</TableCell>
                    </TableRow>
                </TableHead>
                
                <TableBody>
                    
                    {
                        data.map((item,index)=>
                            <TableRow key={index}>
                                <TableCell>{item.name}</TableCell>
                                <TableCell>{item.subject_name}</TableCell>
                                <TableCell>{item.u_date}</TableCell>
                                <TableCell>{item.u_time}</TableCell>
                                <TableCell>
                                    {
                                        item.attachment === "null" ? 'Not available' : (
                                            <a href={ImageApi+'ac_register/'+item.attachment} target={'_blank'} download>
                                                <Button>
                                                    Download
                                                </Button>
                                            </a>


                                        )
                                    }
                                </TableCell>
                                <TableCell>
                                <Button onClick={()=>onView(item.description)}>View Description</Button>
                                </TableCell>
                                
                            </TableRow>
                        )
                    }
                </TableBody>
            </Table>
            <Dialog open={view} onClose={()=>setView(false)}>
                <DialogContent>
                <div dangerouslySetInnerHTML={{__html : viewItem}}>

                </div>
                </DialogContent>
            </Dialog>
            <SnakBar massg={massg} setMassg={setMassg} />
        </TableContainer>
    )
}