import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import ReceiptIcon from '@material-ui/icons/Receipt';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import RateReviewIcon from '@material-ui/icons/RateReview';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import LabelIcon from '@material-ui/icons/Label';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ExplicitIcon from '@material-ui/icons/Explicit';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
export const mainListItems = (
  <div>
   <Link to="/Home/" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <SupervisorAccountIcon />
      </ListItemIcon>
      <ListItemText primary="Student Manager" />
    </ListItem>
    </Link>
    
    <Link to="/Home/AchademicRegister/" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <ReceiptIcon />
      </ListItemIcon>
      <ListItemText primary="Achademic Registger" />
    </ListItem>
    </Link>
    <Link to="/Home/AddCourse" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <NoteAddIcon />
      </ListItemIcon>
      <ListItemText primary="Add Course" />
    </ListItem>
    </Link>
    <Link to="/Home/AllCourses" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <LocalLibraryIcon />
      </ListItemIcon>
      <ListItemText primary="All Courses" />
    </ListItem>
    </Link>
    <Link to="/Home/Messages" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <MailIcon />
      </ListItemIcon>
      <ListItemText primary="Messages" />
    </ListItem>
    </Link>
    <Link to="/Home/RevHome" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <RateReviewIcon />
      </ListItemIcon>
      <ListItemText primary="Testnomials" />
    </ListItem>
    </Link>
    <Link to="/Home/Gallery" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <PermMediaIcon />
      </ListItemIcon>
      <ListItemText primary="Gallery" />
    </ListItem>
    </Link>
    <Link to="/Home/Faculty" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <SupervisorAccountIcon />
      </ListItemIcon>
      <ListItemText primary="Genosis stuf" />
    </ListItem>
    </Link>
    <Link to="/Home/DepartmentHome" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <SupervisorAccountIcon />
      </ListItemIcon>
      <ListItemText primary="Department and Designation" />
    </ListItem>
    </Link>
    <Link to="/Home/Notice" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <EventAvailableIcon />
      </ListItemIcon>
      <ListItemText primary="Notice & Event" />
    </ListItem>
    </Link>
    <Link to="/Home/Tagline" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <LabelIcon />
      </ListItemIcon>
      <ListItemText primary="Tagline" />
    </ListItem>
    </Link>
    
    <Link to="/Home/SubjectManager" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <MenuBookIcon />
      </ListItemIcon>
      <ListItemText primary="SubjectManager" />
    </ListItem>
    </Link>
    <Link to="/Home/ContanetManager" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <ExplicitIcon />
      </ListItemIcon>
      <ListItemText primary="ContanetManager" />
    </ListItem>
    </Link>
    
    <Link to="/Home/ProcceddingBook" style={{textDecoration: 'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <ImportContactsIcon />
      </ListItemIcon>
      <ListItemText primary="Proceeding Book" />
    </ListItem>
    </Link>
    <Link to="/Home/LogOut" style={{textDecoration :'none',color : '#000'}}>
    <ListItem button>
      <ListItemIcon>
        <ExitToAppIcon />
      </ListItemIcon>
      <ListItemText primary="Log Out" />
    </ListItem>
    </Link>
  </div>
);

// export const secondaryListItems = (
//   <div>
//     <ListSubheader inset>Saved reports</ListSubheader>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Current month" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Last quarter" />
//     </ListItem>
//     <ListItem button>
//       <ListItemIcon>
//         <AssignmentIcon />
//       </ListItemIcon>
//       <ListItemText primary="Year-end sale" />
//     </ListItem>
//   </div>
// );