import React from "react";
import {
    Container, Grid, ListItem, ListItemText,List, Divider, Paper, IconButton, LinearProgress
} from '@material-ui/core'
import ContentInsert from "./insertContent";
import { Api } from "../../api/apiv2";
import ContentUpdate from "./updateContent";
import DeleteIcon from '@material-ui/icons/Delete';
import SnakBar from "../../consts/message";

export default function ContanetManager (){
    const [contentHead, setContentHead] = React.useState([{
        name : 'Add New'
    }])
    const [view, setView] = React.useState(null)
    const [refresh, setRefresh] = React.useState({})
    const [massg,setMassg] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const handleClick = (item)=>{
        if(item.name === 'Add New'){
            setView(<ContentInsert />)
        }else{
            setView(<ContentUpdate content={item} />)
        }
    }

    React.useEffect(()=>{
        
        fetch(Api+'contents')
        .then(res=>res.json())
        .then(res=>{
            setContentHead(contentHead.concat(res))
        })
        .catch(err=>{
           console.log(err)
        })
    },[])

    const onDelete = (id)=>{
        setLoading(true)
                fetch(Api+'delete_content',{
                  method : 'POST',
                  body : JSON.stringify({id : id})
                })
                .then(res=>res.json())
                .then(res=>{
                  setLoading(false)
                  if(res.code === 200){
                      setRefresh(Math.random())
                      
                    setMassg({
                      open : true,
                      severity : 'success',
                      massg : res.massg
                    })
                  }else{
                    setMassg({
                      open : true,
                      severity : 'error',
                      massg : res.massg
                    })
                  }
                })
                .catch(err=>{
                  setLoading(false)
                  setMassg({
                    open : true,
                    severity : 'error',
                    massg : 'Faild to connect to the server'
                  })
                })
    }

    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                {loading ? <LinearProgress /> : ''}
                <List component={Paper}>
                {
                    contentHead.map((item,index)=>
                        <div>
                            <ListItem onClick={()=>handleClick(item)} key={index}  button>
                                <ListItemText primary={item.name} />
                                {index != 0 ? (<IconButton onClick={()=>onDelete(item.id)}>
                                    <DeleteIcon />
                                </IconButton>) : ''}
                            </ListItem>
                            <Divider />
                        </div>
                    )
                }
                </List>
                </Grid>
                <Grid item xs={12} sm={8}>
                <Paper style={{padding : 10}}>
                {
                    view 
                }
                
                </Paper>
                
                </Grid>
            </Grid>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}