import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import {Link} from 'react-router-dom';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { Api } from '../../api/apiv2';
import { useHistory } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { CircularProgress, Paper } from '@material-ui/core';
function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" style={{textDecoration : 'none'}}>
        Gnosis Institute
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function Auth() {
  const history = useHistory()
  const classes = useStyles();
  const [phoneNo, setphoneNo] = React.useState('')
  const [pass, setPass] = React.useState('')
  const [err, setErr] = React.useState({})
  const [open, setOpen] = React.useState(false);
  const [Loading, setLoading] = React.useState(false)
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  React.useEffect(()=>{
    const token = localStorage.getItem('authenticated')
    if(token != null){
      history.push('Home/')
    }
    

    
  },[])

  const onSubmit = (e)=> {
    e.preventDefault()
    if(validated()){
      setLoading(true)
      fetch(Api+'admin_auth',{
        method :'POST',
        body : JSON.stringify({
          email : phoneNo,
          pass : pass
        })
      })
      .then(Response => Response.json())
      .then(data=> {
        console.log(data)
        setLoading(false)
        if(data.code === 200){
          localStorage.setItem('authenticated', true)
          history.push('/Home/',{authenticated : true})
        }else {
          handleClick()
        }
      })
      .catch(err=>{
        console.log(err)
        handleClick()
      })
    }
  }
  const validated = ()=> {
    let valid = true
    let error = {}
    // if(!phoneNo){
    //   valid = false
    //   error["phoneNo"] = true

    // }else 
    if(!pass){
      valid = false
      error["pass"] = true
    }

    setErr(error)
    return valid
  }

  return (
    <Container component="main" maxWidth="xs">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert severity="error">Entered wrong credential! Please try again</Alert>
      </Snackbar>
      <CssBaseline />
      <Paper style={{padding : 10}}>
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          {/* <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phoneNo"
            label="Email"
            name="phoneNo"
            autoComplete="phoneNo"
            autoFocus
            value = {phoneNo}
            onChange={({target : {value}})=>setphoneNo(value)}
            error={err.phoneNo}
          /> */}
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value = {pass}
            onChange={({target : {value}})=>setPass(value)}
            error={err.pass}
          />
          {
            Loading ? (
              <CircularProgress color="primary" />
            ) : (
              <div></div>
            )
          }
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e)=>onSubmit(e)}
          >
            Sign In
          </Button>
          
        </form>
      </div>
      </Paper>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}