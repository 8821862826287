import React from 'react'
import {
    Container,
    TextField,
    InputBase,
    FormControl,
    Paper,
    Button,
    LinearProgress
} from '@material-ui/core'
import { Api } from '../../api/apiv2'
import SnakBar from '../../consts/message'
export default function AddReview(){

    const [formData, setFormData] = React.useState({})
    const [err, setErr] = React.useState({})
    const [status, setStatus] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const onChange = (e)=>{
        setFormData({
            ...formData,
            [e.target.name] : e.target.value
        })
    }

    const validate =()=>{
        let valid = true
        let error = {}
        if(!formData.name){
            valid = false
            error['name'] = true
        }
        if(!formData.massg){
            valid = false
            error['massg'] = true
        }
        if(!formData.location){
            valid = false
            error['location'] = true
        }

        setErr(error)

        return valid
    }

    const onSubmit = ()=>{
        setLoading(true)
        if(validate()){
            fetch(Api+'updateReview',{
                method : 'POST',
                body : JSON.stringify(formData)
            })
            .then(Response=>Response.json())
            .then(data=>{
                setLoading(false)
                if(data.status === 201){
                    setStatus({
                        severity : 'success',
                        massg : data.massg,
                        open : true
                    })
                }else{
                    setStatus({
                        severity : 'error',
                        massg : data.massg,
                        open : true
                    })
                }
            })
            .catch(err=>
                {
                setLoading(false)
                setStatus({
                    severity : 'error',
                    massg : 'Faild to connect to the server',
                    open : true
                }) }   
            )
        }
    }

    return(
        <Container component={Paper} style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent : 'center'
        }}>
            {
                loading ? <LinearProgress /> : ''
            }
            <TextField
                label="Reviewer name"
                variant="outlined"
                value={formData.name}
                name="name"
                onChange={onChange}
                error={err.name}
            />
            <TextField
                label="Reviewer location"
                variant="outlined"
                value={formData.location}
                name="location"
                onChange={onChange}
                error={err.location}
            />
            <FormControl>
                <InputBase
                    placeholder="Reviewr Massage"
                    multiline
                    minRows={7}
                    style={{
                        border: '1px solid',
                        marginTop: 20,
                        borderRadius : 5,
                        borderColor : err.massg ? 'red' : '#7a7a7a'
                        
                    }}
                    value={formData.massg}
                    name="massg"
                    onChange={onChange}
                    error={err.massg}
                />
            </FormControl>
            <Button
                variant="contained"

                onClick={onSubmit}
                style={{marginTop : 15,marginBottom : 15}}
            >
                submit
            </Button>
             <SnakBar setMassg={setStatus} massg={status} />
        
        </Container>
    )
}