import React from 'react'
import  { Button, Container, Divider, Grid,LinearProgress,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,IconButton,Tooltip} from '@material-ui/core'
import { Api,ImageApi } from '../../api/apiv2'
import FileResizer from 'react-image-file-resizer'
import SnakBar from '../../consts/message'
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { Save } from '@material-ui/icons'
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
export default function Notice(){
    const [data,setData] = React.useState([])
    const [sendData, setSendData] = React.useState({
        headline : '',
        short_description : '',
        date : '',
    })
    const [err, setErr] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [refresh, setRefresh] = React.useState(Math.random())
    const [massg, setMassg] = React.useState({})
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    React.useEffect(()=>{
        fetch(Api+'notice')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[refresh])

    const onChange = (e)=>{
        setSendData({
            ...sendData,
            [e.target.name] : e.target.value
        })
        
    }

    const validate = ()=>{
        let valid =true
        let err={}

        Object.keys(sendData).map(item=>{
            if(!sendData[item]){
                valid = false
                err[item] = true
            }
        })
        setErr(err)

        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
        setLoading(true)
        const formData = new FormData
        Object.keys(sendData).map(item=>{
            formData.append(item,sendData[item])
        })
        formData.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
        fetch(Api+'addNotice',{
            method : 'POST',
            body : formData
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : res.status,
                severity : res.code === 200 ? 'success' : 'error'
            })
            setRefresh(Math.random())
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
        }
    }

    const onDelete = (id)=>{
        setLoading(true)
        fetch(Api+'noticeDelete',{
            method : 'POST',
            body : JSON.stringify({
                id : id,
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setRefresh(Math.random())
            console.log(res)
            setMassg({
                open : 'true',
                massg : res.status,
                severity : res.code === 200 ? 'success' : 'error'
            })
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
    }
    return(
        <Container>
            <Grid container spacing={2}>
                <Grid xs={12} sm={6}>
                <Paper style={{padding: 10}}>
                    <h3>Upload new Notice & Event</h3>
                    <Divider />
                    {
                        loading ? <LinearProgress /> : ''
                    }
                    <TextField 
                     variant="outlined"
                     value={sendData.headline}
                     onChange={onChange}
                     error={err.headline}
                     label="Headline"
                     name="headline"
                     style={{marginTop: 10}}
                     fullWidth
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.short_description}
                     onChange={onChange}
                     error={err.short_description}
                     label="Short Description"
                     style={{marginTop: 10}}
                     fullWidth
                     name="short_description"
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.date}
                     onChange={onChange}
                     error={err.date}
                     helperText="Upload Date"
                     style={{marginTop: 10}}
                     fullWidth
                     name="date"
                     type='date'
                    />
                     <Editor
                        editorState={editorState}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        onEditorStateChange={onEditorStateChange}
                        editorStyle={{border : '1px solid ',minHeight : 200, borderColor : err.editorState ? 'red' : '#e5e5e5'}} //err.editorState ? 'red' :
                        toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><Save /></Tooltip></IconButton>]}
                    
                        />
                        <textarea
                        disabled
                        style={{display: 'none'}}
                        value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                        // onChange={({target : {value}})=>setData(value)}
                    />
                    <Button
                     variant="contained"
                     style={{marginTop: 10}}
                     fullWidth
                     color="primary"
                     onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </Paper>
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell>Headline</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableHead>
                            <TableBody>
                            {
                                data.map((item,index)=>
                                    <TableRow key={index}>
                                        <TableCell>{item.headline}</TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={()=>onDelete(item.id)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}