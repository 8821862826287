import { Container, FormControl, InputLabel, MenuItem, Paper, Select, TextField, Grid, Button, LinearProgress, Table, TableContainer, TableHead, TableCell, TableBody, TableRow, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React from "react";
import { Api } from "../../api/apiv2";
import SnakBar from "../../consts/message";



export default function DepartmentHome(){
    const [formData, setFormData] = React.useState({})
    const [err, setERr] = React.useState({})
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [data, setData] = React.useState([])
    const [refresh, setRefresh] = React.useState(Math.random())
    React.useEffect(()=>{
        fetch(Api+'departments')
        .then(res=>res.json())
        .then(res=>setData(res))
        .catch(err=>{
           console.log(err)
        })
    },[refresh])
    const handleChange = (e)=>{
        setFormData({
          ...formData,
          [e.target.name] : e.target.value  
        })
    }
    const validate = ()=>{
        let valid = true;
        let err = {}

        if(!formData.name){
            valid = false;
            err['name'] = true
        }
        if(!formData.type){
            valid = false;
            err['type'] = true
        }

        setERr(err)

        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
        setLoading(true)
        fetch(Api+'updateDepartment',{
          method : 'POST',
          body : JSON.stringify(formData)
        })
        .then(res=>res.json())
        .then(res=>{
          setLoading(false)
          setRefresh(Math.random())
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
        }
    }

    const onDelete = (id)=>{
        setLoading(true)
        fetch(Api+'deleteDeprtment',{
          method : 'POST',
          body : JSON.stringify({id : id})
        })
        .then(res=>res.json())
        .then(res=>{
          setLoading(false)
          setRefresh(Math.random())
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
    }
    return(
        <Container>
        <Grid container spacing={2}>
            <Grid item xs={12} sm={5}>
            <Paper style={{padding: 20}}>
                {loading ? <LinearProgress /> : ''}
                <TextField
                    label="Name"
                    variant="outlined"
                    onChange={handleChange}
                    name="name"
                    value={formData.name}
                    error={err.name}
                    fullWidth
                    style={{marginTop: 20}}
                />
                <FormControl fullWidth style={{marginTop: 10}}>
                              <InputLabel id="demo-simple-select-label">Type</InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={formData.type}
                                
                                name="type"
                                error={err.type}
                                onChange={handleChange}
                                
                              >
                                <MenuItem value={false}>None</MenuItem>
                                <MenuItem value="department">department</MenuItem>
                                <MenuItem value="designation">designation</MenuItem>
                              </Select>
                </FormControl>
                <Button variant="contained" onClick={onSubmit} color="primary" fullWidth style={{marginTop: 10}}>
                    Submit
                </Button>
            </Paper>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Paper>
                
                    <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>Name</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Update</TableCell>
                        </TableHead>
                        <TableBody>
                        {
                            data.map((item,index)=>
                                <TableRow key={index}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.type}</TableCell>
                                    <TableCell>
                                        <IconButton onClick={()=>onDelete(item.id)}>
                                            <Delete />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            )
                        }
                        </TableBody>
                    </Table>
                    </TableContainer>
               
                </Paper>
            </Grid>
       
        </Grid>
        <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}