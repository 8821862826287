import React, { Component } from 'react';
import { EditorState, convertToRaw,ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Button, IconButton, Tooltip, LinearProgress,TextField, FormControl, Select, InputLabel, MenuItem } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { Api } from '../../api/apiv2';
import SnakBar from '../../consts/message';
export default function ContentUpdate(props){
    const [editorState, setEditorState] = React.useState(EditorState.createEmpty())
    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }
    const [loading, setLoading] = React.useState(false)
    const [formData,setFormData] = React.useState('')
    const [err, setErr] = React.useState({})
    const [massg,setMassg] = React.useState({})
    React.useEffect(()=>{
        if(!props.content){
            
        }else {
            setFormData({
                name : props.content.name,
                image : props.content.image,
                type : props.content.type,
                id : props.content.id,
                p_value : props.content.priority_value
            })
            const html =  props.content.content;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEditorState(editorState)
            }
        }
    },[props.content.id])
    
    const handleChange = (event)=>{

            setFormData({
                ...formData,
                [event.target.name] : event.target.value
            })
        
    }

    const onSubmit = ()=>{

            setLoading(true)
            const data = new FormData
            data.append('content',draftToHtml(convertToRaw(editorState.getCurrentContent())))
            data.append('type',formData.type)
            data.append('name', formData.name)
            data.append('id', formData.id)
            fetch(Api+'ContentUpdate',{
                method : 'POST',
                body : data
            })
            .then(Response=>Response.json())
            .then(data=>{
                console.log(data)
                setLoading(false)
                if(data.status === 201){
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'success'
                    })
                }else{
                    setMassg({
                        open : true,
                        massg : data.massg,
                        severity : 'error'
                    })
                }
            })
            .catch(err=>{
                setMassg({
                    open : true,
                    massg : 'Faild to connect to the server',
                    severity : 'error'
                })
            })
            
    }

    

    return (
        <div>
                {
                    loading ? <LinearProgress color="primary" style={{width: '100%'}} /> : ''
                }
            
            <TextField
                name = "name"
                label='Name of new head'
                value={formData.name}
                onChange={handleChange}
                error={err.name}
                fullWidth
                style={{margin: 10}}
            />
           <FormControl fullWidth style={{marginTop: 10}}>
                <InputLabel id="demo-simple-select-label">Type</InputLabel>
                <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formData.type}
                label="Type"
                name="type"
                error={err.type}
                onChange={handleChange}
                                
                >
                <MenuItem value={'nav_bar'}>Nav Bar</MenuItem>
                <MenuItem value={'about'}>about</MenuItem>
                <MenuItem value={'administrator'}>administrator</MenuItem>
                </Select>
            </FormControl>

            <Editor
                editorState={editorState}
                wrapperClassName="wrapper-class"
                editorClassName="editor-class"
                toolbarClassName="toolbar-class"
                onEditorStateChange={onEditorStateChange}
                editorStyle={{border : '1px solid #e5e5e5',minHeight : 200}}
                toolbarCustomButtons={[<IconButton disabled={loading} onClick={onSubmit}><Tooltip title="save"><SaveIcon /></Tooltip></IconButton>]}
               
                />
                <textarea
                disabled
                style={{display: 'none'}}
                value={draftToHtml(convertToRaw(editorState.getCurrentContent()))}
                // onChange={({target : {value}})=>setData(value)}
            />


            <SnakBar setMassg={setMassg} massg={massg} />
            
        </div>
    )
}
