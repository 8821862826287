import React from 'react'

export default function LogOut(){
    React.useEffect(()=>{
        localStorage.removeItem('authenticated')
        window.location.replace('/')
    })

    return (
        <h1>loging out....................</h1>
    )
}