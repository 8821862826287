import React from 'react'
import {Container, Grid, TextField, Paper, Button, LinearProgress, TableContainer, Table, TableHead, TableCell, TableBody, TableRow, IconButton} from '@material-ui/core'
import SnakBar from '../../consts/message'
import { Api } from '../../api/apiv2'
import { Delete } from '@material-ui/icons'

export default function SubjectManager(){
    const [subject, setSubject] = React.useState('')
    const [err, setErr] = React.useState()
    const [loading,setLoading] = React.useState(false)
    const [massg,setMassg] = React.useState({})
    const [allSubject, setAllSubject] = React.useState([])
    const [changedSubject, setChangedSubject] = React.useState({})
    const [refresh, setRefresh] = React.useState(null)
    const onSubmit = ()=>{
        if(subject != ''){
            setLoading(true)
                    fetch(Api+'addSubject',{
                      method : 'POST',
                      body : JSON.stringify({subject : subject})
                    })
                    .then(res=>res.json())
                    .then(res=>{
                      setLoading(false)
                      if(res.code === 200){
                        setRefresh(Math.random())
                        setMassg({
                          open : true,
                          severity : 'success',
                          massg : res.massg
                        })
                      }else{
                        setMassg({
                          open : true,
                          severity : 'error',
                          massg : res.massg
                        })
                      }
                    })
                    .catch(err=>{
                      setLoading(false)
                      setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Faild to connect to the server'
                      })
                    })
        }else {
            setErr(true)
        }
    }

    React.useEffect(()=>{
        fetch(Api+'allSubject')
        .then(res=>res.json())
        .then(res=>setAllSubject(res))
        .catch(err=>{
           console.log(err)
        })
    },[refresh])

    const onChangeSubject = (value,id)=>{
        setChangedSubject({
            value : value,
            id : id
        })
    }

    const onSubjectUpdate = (id)=>{
        if(id === changedSubject.id){
            setLoading(true)
                    fetch(Api+'updateSubject',{
                      method : 'POST',
                      body : JSON.stringify(changedSubject)
                    })
                    .then(res=>res.json())
                    .then(res=>{
                      setLoading(false)
                      if(res.code === 200){
                        setRefresh(Math.random())
                        setMassg({
                          open : true,
                          severity : 'success',
                          massg : res.massg
                        })
                      }else{
                        setMassg({
                          open : true,
                          severity : 'error',
                          massg : res.massg
                        })
                      }
                    })
                    .catch(err=>{
                      setLoading(false)
                      setMassg({
                        open : true,
                        severity : 'error',
                        massg : 'Faild to connect to the server'
                      })
                    })
        }else {
            setMassg({
                open : true,
                severity : 'error',
                massg : 'Something went wrong, Please try again'
              })
        }
    }

    const onDelete = (id)=>{
      setLoading(true)
        fetch(Api+'deleteSubject',{
          method : 'POST',
          body : JSON.stringify({id : id})
        })
        .then(res=>res.json())
        .then(res=>{
          setLoading(false)
          setRefresh(Math.random())
          if(res.code === 200){
            setMassg({
              open : true,
              severity : 'success',
              massg : res.massg
            })
          }else{
            setMassg({
              open : true,
              severity : 'error',
              massg : res.massg
            })
          }
        })
        .catch(err=>{
          setLoading(false)
          setMassg({
            open : true,
            severity : 'error',
            massg : 'Faild to connect to the server'
          })
        })
    }

    return(
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={4}>
                    {loading ? <LinearProgress /> : ''}
                    <Paper style={{padding: 10}}>
                        <TextField
                            name="subject"
                            value={subject}
                            onChange={({target : {value}})=>setSubject(value)}
                            error={err}
                            variant="outlined"
                            label="subject name"
                            style={{marginBottom : 10}}
                        />
                        <Button variant="contained" color="primary" onClick={onSubmit}>
                            Add Subject
                        </Button>
                    </Paper>
                    
                </Grid>
                <Grid item xs={12} sm={8}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableCell>id</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Edit</TableCell>
                            <TableCell>Update</TableCell>
                            <TableCell>Delete</TableCell>
                        </TableHead>
                        <TableBody>
                        {
                            allSubject.map((item,index)=>
                                <TableRow>
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>{item.subject_name}</TableCell>
                                    <TableCell>
                                        <TextField
                                            label="subject name"
                                            variant="outlined"
                                            size='small'
                                            onChange={({target : {value}})=>onChangeSubject(value,item.id)}
                                        />
                                    </TableCell>
                                    <TableCell><Button onClick={()=>onSubjectUpdate(item.id)}>Update</Button></TableCell>
                                    <TableCell>
                                      <IconButton onClick={()=>onDelete(item.id)}>
                                        <Delete />
                                      </IconButton>
                                    </TableCell>
                                </TableRow>  
                            )
                        }
                        </TableBody>
                    </Table>
                </TableContainer>
                </Grid>
            </Grid>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}