import React from 'react'
import clsx from 'clsx';
import { Grid,Paper,makeStyles,Box,CircularProgress, Container, Avatar, IconButton, Dialog, DialogContent, DialogTitle, Button } from '@material-ui/core'
import Sales from '../home/totSales'
import Copyright from '../copyright'
import { Redirect , useHistory} from 'react-router';
import {Api,ImageApi} from './../../api/apiv2'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {ChevronRight} from '@material-ui/icons'
const useStyles = makeStyles((theme) => ({
    
    fixedHeight: {
      height: 'auto',
    },
    table: {
      minWidth: 'auto',
    },
  }));
export default function Dashboard(){
    const history = useHistory()
    const classes = useStyles()
    const fixedHeightPaper = clsx(classes.fixedHeight);
    const [Data, setData] = React.useState([])
    const [loading, setLoading] = React.useState(false)
    const [dialog, setDialog] = React.useState(false)
    const [selectedItem, setSelecteditem] = React.useState({})
    const Authenticate = ()=>{
      let bool = true
      const token =  localStorage.getItem('authenticated');
      if(token != null){
          bool = true
      }else {
          bool = false
      }
  
      return  bool
  }
    React.useEffect(()=>{
     
      if(!Authenticate()){
        history.push('/')
        
       
      }else {
        
        fetch(Api+'admissions')
        .then(response=>response.json())
        .then(data=>{
          setData(data)
          setLoading(false)
          console.log(data)
        })
        .catch(err=>console.log(err))
      } 
      
    },[])

    const handleClick = (item)=>{
      setSelecteditem(item)
      setDialog(true)
    }
    return (
        <Container>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableCell></TableCell>
                <TableCell>
                  Name
                </TableCell>
                <TableCell>
                  Fathers Name
                </TableCell>
                <TableCell>
                  Contact No
                </TableCell>
                <TableCell>
                  More
                </TableCell>
                <TableCell>
                  Downloads
                </TableCell>
              </TableHead>
              <TableBody>
                {
                  Data.map((item,index)=>
                    <TableRow key={index}>
                      <TableCell>
                        <Avatar>
                          <img src={ImageApi+'/reg/'+item.passport} style={{width: '100%'}} />
                        </Avatar>
                      </TableCell>
                      <TableCell>
                        {item.name}
                      </TableCell>
                      <TableCell>
                        {item['Fathers Name']}
                      </TableCell>
                      <TableCell>
                        {item['Contact']}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={()=>handleClick(item)}>
                          <ChevronRight />
                        </IconButton>
                      </TableCell>
                      <TableCell>
                        <a href={ImageApi+'/reg/'+item.pan_img} target="_blank"  download><Button color="primary" >Pan Card</Button></a>
                        <a href={ImageApi+'/reg/'+item.passport} target="_blank"  download><Button color="primary" >Passport</Button></a>
                        <a href={ImageApi+'/reg/'+item['Marksheet HSLC']} target="_blank"  download><Button color="primary" >Marksheet HSLC</Button></a>
                        <a href={ImageApi+'/reg/'+item['Marksheet HS']} target="_blank"  download><Button color="primary" >Marksheet HS</Button></a>
                        <a href={ImageApi+'/reg/'+item['Marksheet Degree']} target="_blank"  download><Button color="primary" >Marksheet Degree</Button></a>
                        <a href={ImageApi+'/reg/'+item['Marksheet PGDCA']} target="_blank"  download><Button color="primary" >Marksheet PGDCA</Button></a>
                      </TableCell>
                    </TableRow>
                  )
                }
              </TableBody>
            </Table>
          <Dialog
            open={dialog}
            onClose={()=>setDialog(false)}

          >
            <DialogTitle>
              More Details
            </DialogTitle>
            <DialogContent>
            <TableContainer>
              <Table>
                <TableBody>
                  {
                    Object.keys(selectedItem).map((item,index)=>
                      <TableRow key={index}>
                          <TableCell>{item}</TableCell>
                          <TableCell>{selectedItem[item]}</TableCell>
                      </TableRow>
                    )
                  }
                </TableBody>
              </Table>
            </TableContainer>
            </DialogContent>

          </Dialog>
          </TableContainer>
        </Container>
    )
}