


import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Title from '../../styles/title';
import {Api} from '../../api/apiv2';
import { CircularProgress } from '@material-ui/core';
function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function Sales() {
  const classes = useStyles();
  const date = new Date().toLocaleString()
  const [sellAmount, setSellAmount] = React.useState({})
  const [loading, setLoading] = React.useState(false)
  React.useEffect(()=>{
    setLoading(true)
    fetch(Api+'totSales&seller='+localStorage.getItem('token'))
    .then(Response=>Response.json())
    .then(data=>{
      console.log(data)
      setSellAmount(data)
      setLoading(false)
    })
    .catch(err=>console.log(err))
  },[])
  return (
    <React.Fragment>

      <Title>Total Sales</Title>
      {
               loading ? <CircularProgress style={{margin: 10}} color="primary" /> : ''
            }
      <Typography component="p" variant="h4">
        {sellAmount.amount != null ? sellAmount.amount : 0}
      </Typography>
      <Typography color="textSecondary" className={classes.depositContext}>
        {date}
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={()=>alert('comming soon')}>
          View balance
        </Link>
      </div>
    </React.Fragment>
  );
}