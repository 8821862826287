import React from 'react'
import  { Button, Container, Divider, Grid,LinearProgress,Paper,Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField} from '@material-ui/core'
import { Api,ImageApi } from '../../api/apiv2'
import FileResizer from 'react-image-file-resizer'
import SnakBar from '../../consts/message'
export default function Gallery(){
    const [data,setData] = React.useState([])
    const [sendData, setSendData] = React.useState({
        caption : '',
        description : '',
       
    })
    const [image,setImage] = React.useState()
    const [err, setErr] = React.useState({})
    const [loading, setLoading] = React.useState(false)
    const [refresh, setRefresh] = React.useState(Math.random())
    const [massg, setMassg] = React.useState({})
    React.useEffect(()=>{
        fetch(Api+'gallery')
        .then(res=>res.json())
        .then(data=>setData(data))
    },[refresh])

    const onChange = (e)=>{
        if(e.target.name === 'image'){
            try {
                FileResizer.imageFileResizer(
                e.target.files[0],
                800,
                1000,
                "PNG",
                80,
                0,
                (uri) => {
                   setImage(uri) 
                },
                "file",
                200,
                200
                );
          
            
            } catch (err) {
                console.log(err);
            }
        }else {
            setSendData({
                ...sendData,
                [e.target.name] : e.target.value
            })
        }
        
    }

    const validate = ()=>{
        let valid =true
        let err={}

        Object.keys(sendData).map(item=>{
            if(!sendData[item]){
                valid = false
                err[item] = true
            }
        })
        setErr(err)

        return valid
    }

    const onSubmit = ()=>{
        if(validate()){
        setLoading(true)
        const formData = new FormData
        Object.keys(sendData).map(item=>{
            formData.append(item,sendData[item])
        })
        formData.append('image',image)
        fetch(Api+'addImage',{
            method : 'POST',
            body : formData
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : res.status,
                severity : res.code === 200 ? 'success' : 'error'
            })
            setRefresh(Math.random())
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
        }
    }

    const onDelete = (id,img)=>{
        setLoading(true)
        fetch(Api+'imageDelete',{
            method : 'POST',
            body : JSON.stringify({
                id : id,
                name : img
            })
        })
        .then(res=>res.json())
        .then(res=>{
            setLoading(false)
            setRefresh(Math.random())
            console.log(res)
            setMassg({
                open : 'true',
                massg : res.status,
                severity : res.code === 200 ? 'success' : 'error'
            })
        })
        .catch(err=>{
            setLoading(false)
            setMassg({
                open : 'true',
                massg : "Faild to connect to server",
                severity : 'error'
            })
        })
    }
    return(
        <Container>
            <Grid container spacing={2}>
                <Grid xs={12} sm={4}>
                <Paper style={{padding: 10}}>
                    <h3>Upload new Image</h3>
                    <Divider />
                    {
                        loading ? <LinearProgress /> : ''
                    }
                    <TextField 
                     variant="outlined"
                     value={sendData.caption}
                     onChange={onChange}
                     error={err.caption}
                     label="Caption"
                     name="caption"
                     style={{marginTop: 10}}
                     fullWidth
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.description}
                     onChange={onChange}
                     error={err.description}
                     label="Description"
                     style={{marginTop: 10}}
                     fullWidth
                     name="description"
                    />
                    <TextField 
                     variant="outlined"
                     value={sendData.image}
                     onChange={onChange}
                     error={err.image}
                     helperText='Select Image'
                     style={{marginTop: 10}}
                     type="file"
                     fullWidth
                     name="image"
                    />
                    <Button
                     variant="contained"
                     style={{marginTop: 10}}
                     fullWidth
                     color="primary"
                     onClick={onSubmit}
                    >
                        Submit
                    </Button>
                </Paper>
                </Grid>
                <Grid item xs={12} sm={8}>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableCell>Image</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Caption</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Delete</TableCell>
                            </TableHead>
                            <TableBody>
                            {
                                data.map((item,index)=>
                                    <TableRow key={index}>
                                        <TableCell>
                                            <img src={ImageApi+'gallery/'+item.image} style={{width : 50}} />
                                        </TableCell>
                                        <TableCell>{item.image}</TableCell>
                                        <TableCell>{item.caption}</TableCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell>
                                            <Button color="primary" onClick={()=>onDelete(item.id,item.image)}>
                                                Delete
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
            <SnakBar massg={massg} setMassg={setMassg} />
        </Container>
    )
}